/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { styled } from '@superset-ui/core';
import { Input as AntdInput, InputNumber as AntdInputNumber } from 'antd';

export const Input = styled(AntdInput)`
  background: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.grayscale.base};
  border: 1px solid ${({ theme }) => theme.colors.cardBorderColor};
  border-radius: ${({ theme }) => theme.btnBorderRadius}px;
   .ant-input{
     background-color: unset;
     color: ${({ theme }) => theme.colors.grayscale.base};
   }
  .ant-input-clear-icon{
    color: ${({ theme }) => theme.colors.grayscale.base};
  }
  &:hover,
  &:focus{
    border-color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const InputNumber = styled(AntdInputNumber)`
  background: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.grayscale.base};
  border: 1px solid ${({ theme }) => theme.colors.cardBorderColor};
  border-radius: ${({ theme }) => theme.btnBorderRadius}px;
  &:hover,
  &:focus{
    border-color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const TextArea = styled(AntdInput.TextArea)`
  background: ${({ theme }) => theme.colors.inputBg};
  color: ${({ theme }) => theme.colors.grayscale.base};
  border: 1px solid ${({ theme }) => theme.colors.cardBorderColor};
  border-radius: ${({ theme }) => theme.btnBorderRadius}px;
  &:hover,
  &:focus{
    border-color: ${({ theme }) => theme.colors.primary.base};
  }
`;
